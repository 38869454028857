const data = {
    "settings": {
        "template_id": 2,
        "primaryColor": "#C50000",
        "buttonColor": "#FF3434",
        "buttonBg": "#FFDCDC",
        "darkColor": "#000000",
        "seconderyColor": "#ffffff",
        "boxShadow": "0px 30px 80px 5px rgba(209, 43, 40, 0.3)",
        "bggradient": "linear-gradient(180deg, rgba(209, 43, 40, 0.09) 0%, rgba(255, 255, 255, 0.11) 100%)",
        "contactAddress": "linear-gradient(98.63deg, #FFF8F8 0%, rgba(255, 255, 255, 0) 100%)",
    },
    "location": {
        "id": 167,
        "name": "Sushi Kingdom",
        "category": "Restaurant",
        "url": "http://127.0.0.1:5500/index.html",
        "logo": "./img/v1/logo.png",
        "logo_footer": "./img/v1/logo.png",
        "banner": {
            "title": "Sushi Kingdom",
            "description": "We invite you to savor the flavors and immerse yourself in the kingdom of sushi- Where sushi dreams come true!",
            "image": "./img/v1/hero_bg.webp",
            "thumbnail": "./img/v1/hero_image.webp",
            "button_name": "Order Now",
            "button_link": "https://order.kloudeats.com/l/sushi-kingdom-glen-allen/190"
        },
        "gallery": [
            {
                "original": "./img/v1/gallery/1.webp",
                "thumbnail": "/img/v1/gallery/1.webp"
            },
            {
                "original": "./img/v1/gallery/2.webp",
                "thumbnail": "/img/v1/gallery/2.webp"
            }, {
                "original": "./img/v1/gallery/3.webp",
                "thumbnail": "/img/v1/gallery/3.webp"
            }, {
                "original": "./img/v1/gallery/3.webp",
                "thumbnail": "/img/v1/gallery/3.webp"
            }, {
                "original": "./img/v1/gallery/4.webp",
                "thumbnail": "/img/v1/gallery/4.webp"
            }, {
                "original": "./img/v1/gallery/5.webp",
                "thumbnail": "/img/v1/gallery/5.webp"
            }, {
                "original": "./img/v1/gallery/6.webp",
                "thumbnail": "/img/v1/gallery/6.webp"
            },
        ],
        "about": [
            {
                "img": "./img/v1/store.webp",
                "title": "It’s more than just a meal",
                "description": "Welcome to Sushi Kingdom Glen Allen, your go-to all-you-can-eat sushi restaurant in" +
                  " Henrico, VA! Indulge in an exquisite culinary experience as we offer an extensive selection of" +
                  " delicious sushi, expertly crafted by our talented chefs.",
            }
        ],
        "reviews": {
            "image": "./img/v1/review.webp",
            "contents": [
                {
                    "review": "This place is SO CUTE! The food is also absolutely delicious. Service is attentive and friendly. So glad to have a nice place to come to before a movie (it's by the Short Pump Regal theater) or just to chill with friends. They have gone above and beyond to transform this place and I love it! Looking forward to the next trip!",
                    "reviewer": "Danielle Keeton",
                },
                {
                    "review": "My first time here and it’s wonderful. The atmosphere is modern and aesthetically pleasing. Music is catchy and ambient. Food is tasty and doesn’t take long to arrive. Service is friendly and pretty fast despite it being busy. I’ve been to Sushi King and other places like this, but this one is my favorite.",
                    "reviewer": "Julia",
                },
                {
                    "review": "My husband and I ate here two days in a row and both times we were very satisfied. The service was great, the staff was very attentive and the food was delicious. The sushi tasted so fresh and there was a good variety too. Will definitely come back!",
                    "reviewer": "Maria Lyn Hunley",
                },
                {
                    "review": "This place definitely has more menu options in all categories (soups/salads, appetizers, sushi, udon, hibachi) than another AYCE sushi restaurant I used to go to. I love that their hibachi dishes don't come with rice or vegetables as I wouldn't want to fill up on those. Our server was great and the table staff were quick to clear off our empty plates. Overall, we had a great experience!",
                    "reviewer": "May S",
                }
            ]
        },
        "social_media": [
            {
                icon: "facebook",
                link: "https://www.facebook.com/sushikingdom.glenallen"
            },
            {
                icon: "instagram",
                link: "https://instagram.com/sushikingdom.glenallen"
            },
        ],
        "contact_us": {
            "phone": '8048560552',
            "address": "4300 Pouncey Tract Rd Suite A, Glen Allen, VA 23060",
            "background": "./img/v1/contactus-banner.webp",
            "business_hours": "Sun:\t\t12 PM - 9:45 PM\nMon - Thu:\t11 AM – 2:45 PM\n\t\t\t4:30 PM –" +
              " 9:45 PM\nFri:\t\t\t11 AM - 2:45 PM\n\t\t\t4:30 PM - 10:45 PM\nSat:\t\t\t11 AM - 10:45 PM"
        },
    }
}
export default data
